<!--
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-05-20 17:04:01
 * @LastEditors: hutian
 * @LastEditTime: 2021-05-21 15:28:23
-->
<template>
<div class='check-order-info'>
    <a-modal v-model="visible" title="订单详情" :width="1000" :maskClosable="false">
      <div class="order_info">
        <div class="order_title">
          <span class="order_code">订单编号： {{detail.orderCode}}</span>
          <a-button type="default">作废</a-button>
        </div>
        <div class="row">
          <div class="column" style="width: 35%">
            <div>创建时间：2021.02.20 15:44:23</div>
            <div>支付方式：菱感余额 / 微信 / 支付宝</div>
          </div>
          <div class="column" style="width: 35%">
            <div>付款时间：2021.02.20 15:44:23</div>
            <div>订单备注：尽快发货</div>
          </div>
          <div class="column">
            <div style="color: #999999">订单状态</div>
            <div style="font-size: 18px; font-weight: bold;">未付款</div>
          </div>
          <div class="column">
            <div style="color: #999999">订单金额</div>
            <div style="font-size: 18px; font-weight: bold;">¥ 568.08</div>
          </div>
        </div>
      </div>
      <div class="split-line"></div>
      <div class="client_info">
        <div class="client_info_title">客户信息</div>
        <div class="line"></div>
        <div class="row client_info_row" style="padding-left: 20px;">
          <div style="width: 33%">经销商名称：{{detail.dealerName}}</div>
          <div style="width: 33%">联系人：小新</div>
          <div style="width: 33%">联系电话：{{detail.customerContact}}</div>
          <div>收货地址：{{detail.provinceName}}{{detail.cityName}}{{detail.areaName}}{{detail.address}}</div>
        </div>
      </div>
      <div class="split-line"></div>
      <div class="product_info_title">商品信息</div>
      <div class="line"></div>
      <div class="product_info">
        <a-table :data-source="tableData" :rowKey="record => record.id" :pagination="false" bordered>
          <a-table-column title="商品名称" data-index="proName" ></a-table-column>
            <a-table-column title="标签图标" data-index="imgUrl" align="center">
              <template slot-scope="text">
                <ZoomMedia style="height: 20px;" :value="text"></ZoomMedia>
              </template>
            </a-table-column>
            <a-table-column title="分类" data-index="proOrgCodeName" align="center"></a-table-column>
            <a-table-column title="规格" data-index="specValue" align="center"></a-table-column>
            <a-table-column title="品牌" data-index="brandName" align="center"></a-table-column>
            <a-table-column title="建议零售价" data-index="proPrice" align="center">
              <template slot-scope="text">{{text.toFixed(2)}}</template>
            </a-table-column>
            <a-table-column title="数量" data-index="purchaseNum" align="" :width="120"></a-table-column>
            <a-table-column title="单位" data-index="proUnit" align="center" :width="150"></a-table-column>
        </a-table>
      </div>
    </a-modal>
</div>
</template>

<script>
import { selectByIdOrderInfo } from "../api/OrderInfoApi";

export default {
  name:'',
  data() {
    return {
      visible: false,
      tableData: [],
      detail: {}
    }
  },

  computed: {

  },
  
  created() {

  },

  methods: {
    isShow(row) {
      this.visible = true
      selectByIdOrderInfo(row.id).then(res => {
        this.detail = res.body
        this.tableData = res.body.orderInfoDetailVOList
      })
    }
  }
}
</script>

<style lang='scss' scoped>
.order_title, .row {
  display: flex;
  justify-content: space-between;
}
.row {
  color: rgba(0, 0, 0, 0.8);
}
.order_code {
  font-size: 20px;
  font-weight: bold;
  color: rgba(0,0,0, 0.8);
}
.split-line {
  height: 13px;
  background: #ECECEC;
  margin: 15px 0;
  width: 105%;
  margin-left: -24px;
}
.line {
  width: 105%;
  margin: 15px 0;
  height: 1px;
  background: #E9E9E9;
  margin-left: -24px;
}
.client_info {
  &_row {
    flex-wrap: wrap;
  }
  &_title {
    font-size: 16px;
    color: #333333;
    line-height: 10px;
  }
}
.product_info_title {
  margin-bottom: 12px;
  font-size: 16px;
  color: #333333;
}
</style>